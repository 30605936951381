
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources } from "./i18nResources";

//Our codebase does not deal with locales for now in the language settings.
//This callback makes sure to load the language only, ignoring the locale.
i18n.on('initialized', _ => {
  const currentLanguage = i18n.language;
  i18n.changeLanguage(currentLanguage.includes('-') ? currentLanguage.split('-')[0] : currentLanguage);
});

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    fallbackLng: ['en', 'fr', 'es'],
    debug: false,
    keySeparator: false,
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    resources
  });

export default i18n; 